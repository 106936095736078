import styled from 'styled-components';

const Label = styled.div`
    font-family: SpaceMono;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    display: inline-block;
    position: relative;

    ::before {
        content: '//';
        color: #9b9b9b;
        margin-right: 10px;
    }

    ::after {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        right: -120px;
        width: 100px;
        height: 20px;
        background-image: 
            radial-gradient(circle at 90px center, rgba(0,0,0,0) 6px, #e31d1a 6px, #e31d1a 8px, rgba(0,0,0,0) 8px),
            linear-gradient(rgba(0,0,0,0) 9px, #e31d1a 9px, #e31d1a 11px, rgba(0,0,0,0) 9px)
        ;
        background-repeat: no-repeat;
        background-size: cover, 84px 20px;
    }
`;

export default Label;
