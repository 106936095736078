import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../layout/wrapper';
import Label from '../../components/label';
import Bootcamp from './bootcamp';
import campus from '../../campus';
import { Tab, TabContainer } from '../../components/tabs';

import data from '../../data.json';

const Section = styled.section`
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #212121;
`;

const About = () => {
    return (
        <Section id="about">
            <Wrapper>
                <Label>&lt;About our Course&gt;</Label>
                <TabContainer>
                    <Tab active>
                        {data.about[campus.JS].title}
                    </Tab>
                </TabContainer>
                <Bootcamp bootcamp={data.about[campus.JS]} />
            </Wrapper>
        </Section>
    );
};

export default About;
