import styled from 'styled-components';

const Button = styled.a`
    cursor: pointer;
    font-weight: 700;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 70px;
    margin-bottom: 20px;
    border: 1px solid #e31d19;
    text-decoration: none;
    color: ${({ white, red }) => (red ? '#e31d19' : white ? '#131313' : '#ffffff')};
    background-color: ${({ outline, white }) =>
        outline ? 'transparent' : white ? '#ffffff' : '#e31d19'};
    
    :hover {
        transform: translateY(2px);
        transition: transform 0.08s ease-in-out;
    }
`;

export default Button;
