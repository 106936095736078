import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import Wrapper from '../../layout/wrapper';
import HeroVideo from './video';
import Menu from './menu';
import Scroller from './scoller';
import { Button } from '../../components/buttons';
import OSLabel from '../outsystems/OSlabel';

import data from '../../data.json';

/* assets */
import videoMp4 from '../../assets/videos/hero-video-transcode.mp4';
import videoWebm from '../../assets/videos/hero-video-transcode.webm';
import poster from '../../assets/videos/hero-video-poster-00001.jpg';
import logo from '../../assets/images/logo_codeforall.svg';

const HeroSection = styled.section`
    min-height: 100vh;
    position: relative;
    background-color: rgba(0, 0, 0, 0.53);
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    @media (min-width: 0) and (max-width: 1024px) {
        min-height: auto;
    }
`;

const HeroVideoBkg = styled.div`
    height: 100%;
    min-height: 100vh;
    background-color: rgba(79, 95, 92, 0.34);

    @media (min-width: 0) and (max-width: 1024px) {
        height: auto;
        min-height: auto;
        background-color: rgba(34, 41, 40, 0.64);
    }
`;

const HeroWrapper = styled(Wrapper)`
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (min-width: 0) and (max-width: 1024px) {
        height: auto;
        min-height: auto;
        justify-content: flex-start;
    }
`;

const Title = styled.h1`
    margin-bottom: 20px;
    color: #ffffff;
`;

const Description = styled.p`
    max-width: 60%;
    margin-bottom: 40px;
    color: #ffffff;
    font-size: 18px;
    line-height: 1.4em;
    font-weight: normal;

    @media (min-width: 0) and (max-width: 768px) {
        max-width: 100%;
        font-size: 16px;
    }
`;

const Blink = keyframes`
  0% {
    color: #e31d1a;
  }
  8% {
    color: white;
  }
  16% {
    color: #e31d1a;
  }
  24% {
    color: white;
  }
  100% {
    color: white;
  }
`;

const Discount = styled.div`
    margin-bottom: 40px;
    line-height: 1.6em;
    color: #ffffff;
`;

const TextWhite = styled.span`
    color: #ffffff;
`;

const TextRed = styled.span`
    color: #e31d1a;
`;

const TextRedBlink = styled.span`
    color: #e31d1a;
    animation: ${Blink} 2s ease-in-out infinite;
`;

const BorderDance = keyframes`
    0% {
        background-position: left top, right bottom, left bottom, right   top;
    }
    100% {
        background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
    }
`;

const SoldOut = styled.div`
    background-image: linear-gradient(90deg, #e31d1a 50%, transparent 50%), linear-gradient(90deg, #e31d1a 50%, transparent 50%), linear-gradient(0deg, #e31d1a 50%, transparent 50%), linear-gradient(0deg, #e31d1a 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
    background-position: left top, right bottom, left bottom, right top;
    animation: ${BorderDance} 1s infinite linear;
    background-color: black;
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 40px;
`;

const daysLeft = endDate =>
    Math.max(0, Math.ceil((Date.parse(endDate) - Date.now()) / (24 * 3600 * 1000)));

const Hero = () => (
    <HeroSection>
        <HeroVideo src={[videoMp4, videoWebm]} poster={poster} />
        <HeroVideoBkg>
            <HeroWrapper>
                <Menu src={logo} items={data.menu.items} action={data.menu.action} />
                <div>
                    <OSLabel />
                    <Title>{data.hero.title}</Title>
                    {data.hero.description.map((line, index) => (
                        <Description key={index}>{line}</Description>
                    ))}
                    <div>
                        <SoldOut>
                            <TextRedBlink>New dates will be announced soon!!</TextRedBlink>  
                        </SoldOut>
                    </div>
                    <Button to="/#campi" as={Link}>
                        &lt;I want to know more&gt;
                    </Button>
                    <Discount>
                        {data.hero.discounts.earlyBird.enabled &&
                            daysLeft(data.hero.discounts.earlyBird.endDate) !== 0 && (
                                <>
                                    <TextWhite>&#47;&#47; DISCOUNTS </TextWhite>
                                    <br />
                                    <TextWhite>&#47;&#47; Early bird: </TextWhite>
                                    <TextRedBlink>==&#62;</TextRedBlink>{' '}
                                    <TextWhite>
                                        {data.hero.discounts.earlyBird.value}% DISCOUNT
                                    </TextWhite>{' '}
                                    <TextRedBlink>&#60;==</TextRedBlink>
                                    <br />
                                    <TextWhite>&#47;&#47; Early bird last call: </TextWhite>
                                    <TextRed>
                                        {' '}
                                        {daysLeft(data.hero.discounts.earlyBird.endDate)} days left
                                    </TextRed>
                                    <br />
                                </>
                            )}
                        {data.hero.discounts.alumni.enabled && (
                            <>
                                <TextWhite>&#47;&#47; Alumni: </TextWhite>
                                <TextRedBlink>==&#62;</TextRedBlink>{' '}
                                <TextWhite>{data.hero.discounts.alumni.value}% DISCOUNT</TextWhite>{' '}
                                <TextRedBlink>&#60;==</TextRedBlink>
                                <br />
                            </>
                        )}
                    </Discount>
                </div>
                <Scroller to="/#campi" />
            </HeroWrapper>
        </HeroVideoBkg>
    </HeroSection>
);

export default Hero;
