import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 0) and (max-width: 768px) {
        margin-bottom: 40px;
    }
`;

const Logo = styled.img`
    display: inline-block;
    width: 100%;
    max-width: 240px;

    @media (min-width: 0) and (max-width: 768px) {
        max-width: 160px;
    }

    @media (min-width: 0) and (max-width: 640px) {
        max-width: 120px;
    }
`;

const MenuItemContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const LinkItem = styled.a`
    text-decoration: none;
    margin-right: 40px;
    color: #fff;
    font-size: 12px;

    // hide menu on small screens
    @media (min-width: 0) and (max-width: 1024px) {
        display: none;
    }
`;

const MenuAction = styled(Link)`
    padding: 12px 16px;
    border-radius: 40px;
    background-color: #fff;
    color: #131313;
    text-decoration: none;
    font-weight: 700;
    text-shadow: none;

    :hover {
        transform: translateY(2px);
        transition: transform 0.08s ease-in-out;
    }

    @media (min-width: 0) and (max-width: 640px) {
        font-size: 12px;
        padding: 8px 16px;
    }
`;

const MenuItem = ({ item }) =>
    item.url ? (
        <LinkItem rel="noreferrer" target="_blank" href={item.url}>
            <strong>{item.label}</strong>
        </LinkItem>
    ) : (
        <LinkItem to={`/#${item.id}`} as={Link}>
            <strong>{item.label}</strong>
        </LinkItem>
    );

const Menu = ({ src, items, action }) => (
    <Container>
        <Logo src={src} />
        <MenuItemContainer>
            {items.map(item => (
                <MenuItem key={item.id} item={item} />
            ))}
            <MenuAction to={`/${action.id}`}>{action.label}</MenuAction>
        </MenuItemContainer>
    </Container>
);

Menu.propTypes = {
    src: PropTypes.node.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    action: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }).isRequired
};

export default Menu;
