import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import campus from '../../campus';
import Wrapper from '../../layout/wrapper';
import Label from '../../components/label';
import { Tab, TabContainer } from '../../components/tabs';

import data from '../../data.json';

const Section = styled.div`
    background-color: #131313;
    padding: 120px 0;

    @media (min-width: 0) and (max-width: 768px) {
        padding: 80px 0;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    @media (min-width: 0) and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

const FaqItem = styled.article`
    width: 100%;
`;

const FaqIcon = styled.div`
    margin-left: 20px;
    min-width: 20px;
    height: 22px;
    width: 22px;
    position: relative;

    ::after {
        content: '';
        height: 2px;
        width: 22px;
        position: absolute;
        top: calc(50% - 1px);
        background-color: #9b9b9b;
        display: block;
        transform-origin: center;

        ${({ opened }) =>
            opened &&
            `
            transform: rotate(0);
            background-color: #e02526;
            
        `}
    }

    ::before {
        content: '';
        height: 22px;
        width: 2px;
        position: absolute;
        left: calc(50% - 1px);
        background-color: #9b9b9b;
        display: block;
        transform-origin: center;

        ${({ opened }) =>
            opened &&
            `
            display: none; 
        `}
    }

    &:hover::after,
    &:hover::before {
        background-color: #e31d1a;
        transition: background-color 0.08s ease-in-out;
    }
`;

const Question = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px 10px;
    border-bottom: 1px dashed #333333;

    h5 {
        color: #ffffff;
    }
`;

const Answer = styled.div`
    font-family: OpenSans;
    font-size: 14px;
    color: #9b9b9b;
    line-height: 1.71;
    padding: 20px 0;
    display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Faq = () => {
    const [itemsExpanded, setExpanded] = useState([]);

    return (
        <Section id="faq">
            <Wrapper>
                <Label>FAQ Yourself</Label>
                <TabContainer>
                    <Tab active>
                        {data.faq[campus.JS].title}
                    </Tab>
                </TabContainer>
                <Container>
                    {data.faq[campus.JS].faqs.map((item, index) => (
                        <FaqItem key={index}>
                            <Question>
                                <h5>{item.question}</h5>
                                <FaqIcon
                                    opened={!!itemsExpanded[index]}
                                    onClick={() =>
                                        setExpanded(
                                            Object.assign([], itemsExpanded, {
                                                [index]: !itemsExpanded[index]
                                            })
                                        )
                                    }
                                />
                            </Question>
                            <Answer show={itemsExpanded[index]}>{item.answer}</Answer>
                        </FaqItem>
                    ))}
                </Container>
            </Wrapper>
        </Section>
    );
};

Faq.propTypes = {
    campusId: PropTypes.string.isRequired
};

Faq.defaultProps = {
    campusId: campus.JS
};

export default Faq;
