import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import scrollImg from '../../assets/images/scroll-icon.svg';

const Jump = keyframes`
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    margin-top: 80px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;

    :hover {
        animation: ${Jump} 0.4s ease-in-out;
    }

    @media (min-width: 0) and (max-width: 768px) {
        margin-top: 40px;
    }
`;

const Anchor = styled(Link)`
    max-width: 100%;
    display: inline-block;
`;

const Scroller = ({ to }) => (
    <Container>
        <Anchor to={to}>
            <img src={scrollImg} alt="" />
        </Anchor>
    </Container>
);

Scroller.propTypes = {
    to: PropTypes.string.isRequired
};

export default Scroller;
