import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Wrapper from '../../layout/wrapper';
import Label from '../../components/label';
import CampusInfo from './campus-info';
import { ButtonContainer, Button } from '../../components/buttons';
import Outsystems from '../outsystems/OSsection';

import data from '../../data.json';

import jsBgImage from '../../assets/images/img-bootcamp-javascript.svg';
import reactBgImage from '../../assets/images/img-bootcamp-react.svg';

const bgMap = {
    js: {
        image: jsBgImage,
        pos: '300px 64%'
    },
    react: {
        image: reactBgImage,
        pos: '300px 80%'
    }
};

const Section = styled.section`
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: #131313;

    @media (min-width: 0) and (max-width: 1024px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 0) and (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 0) and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const CampiLabel = styled.div`
    color: #e31d1a;
    font-size: 16px;
    font-weight: 700;
`;

const CampiTitle = styled.h1`
    color: #fff;
    margin-bottom: 40px;
    margin-top: 20px;
`;

const CampiButtonContainer = styled(ButtonContainer)`
    margin-top: 40px;
`;

const CampusContainer = styled.div`
    width: 100%;

    @media (min-width: 0) and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const Campus = ({ campusId, campus, onFaq }) => (
    <CampusContainer>
        <CampiLabel>Remote</CampiLabel>
        <CampiTitle>{campus.name}</CampiTitle>
        <CampusInfo campus={campus} bgImage={bgMap[campusId].image} bgPos={bgMap[campusId].pos} />
        {/*<Discounts discounts={campus.discounts} />*/}
        <CampiButtonContainer>
            {/*<Button as={Link} to="/apply" state={{ purchase: campusId }}>
                <p>&lt;Apply Now&gt;</p>
            </Button>*/}
            <Button
                outline="true"
                as={Link}
                to="/#faq"
                state={{ campus: campusId }}
                onClick={() => onFaq(campusId)}
            >
                <p>&lt;FAQ Yourself&gt;</p>
            </Button>
        </CampiButtonContainer>
    </CampusContainer>
);

const Campi = ({ onFaq }) => (
    <Section id="campi">
        <Wrapper>
            <Label>&lt;Our Course&gt;</Label>
            <Container>
                <Campus campusId="js" campus={data.campi.js} onFaq={onFaq} />
                <Outsystems />
            </Container>
        </Wrapper>
    </Section>
);

Campi.propTypes = {
    onFaq: PropTypes.func
};

Campi.defaultProps = {
    onFaq: () => {}
};

export default Campi;
