import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Wrapper from '../../layout/wrapper';
import { Button } from '../../components/buttons';

const Section = styled.div`
    background: linear-gradient(90deg, #131313 57px, transparent 1%) 50%,
        linear-gradient(#131313 57px, transparent 1%) 50%, #333;
    background-size: 60px 60px;
    padding: 80px 0;
`;

const Title = styled.h2`
    color: #e02526;
`;

const Text = styled.p`
    color: #9b9b9b;
    font-size: 27px;
    font-weight: 700;
    font-family: SpaceMono;
    line-height: 1.37;
    margin: 40px 0;

    span {
        color: #ffffff;
    }

    @media (min-width: 0) and (max-width: 768px) {
        font-size: 20px;
    }
`;

const Wtf = () => (
    <Section id="wtf">
        <Wrapper>
            <Title>WhyTF?</Title>
            <Text>
                <span>It’s</span> a freaking <span>no brainer</span>! If what you want is to start
                on your path to become an <span>Outsystems Developer</span>, you came to the right place. We have everything you need to help you find your way into the realms of the <span>hottest low-code tool</span> in
                the market!<br />
            </Text>
            <Text>
                <span>Need we say more?!</span> Just buckle up &amp;&amp;{' '}
                <span>start your journey</span> A$AP
            </Text>
            <Text>
                {'//'} If you are <span>still not convinced</span>, drop us a word &amp;&amp; we'll{' '}
                <span>gladly call</span> you back.{' '}
                <span>The only thing we love more than code is people</span>. Trust us!{' '}
                <span>#JoinTheLowCodeHigh</span>
            </Text>
            {/*<Button as={Link} to="/#campi">
                &lt;Apply A$AP&gt;
            </Button>*/}
        </Wrapper>
    </Section>
);

export default Wtf;
