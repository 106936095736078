import React from 'react';
import styled from 'styled-components';

/* assets */
import Outsystems from '../../assets/images/OutSystems-logo-digital-2018-alternative-color.svg';

const OSLabel = styled.div`
    display: flex;
    align-items: center;
`;

const OSText = styled.div`
    color: white;

    @media (min-width: 0) and (max-width: 640px) {
        font-size: 11px;
    }
`;

const OSLogo = styled.div`
    display: inline-block;
    width: 170px;
    height: 40px;
    background-image: url(${Outsystems});
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: 0) and (max-width: 640px) {
        width: 120px;
        height: 28px;
    }
`;

const Label = () => (
    <OSLabel>
        <OSLogo />
        <OSText>| Training Partner</OSText>
    </OSLabel>
);

export default Label;
