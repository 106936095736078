import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../layout/wrapper';

import acLogo from '../../assets/images/logoPTW.svg';
import fbLogo from '../../assets/images/facebook.svg';
import igLogo from '../../assets/images/instagram.svg';
import twLogo from '../../assets/images/twitter.svg';
import ytLogo from '../../assets/images/youtube.svg';
import liLogo from '../../assets/images/linkedin.svg';

const Section = styled.div`
    background-color: #e02526;
    color: #ffffff;
    padding: 30px 0;
`;

const Container = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 0) and (max-width: 768px) {
        flex-direction: column;
    }
`;

const AcLogo = styled.img`
    display: block;

    @media (min-width: 0) and (max-width: 768px) {
        width: 200px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    @media (min-width: 0) and (max-width: 640px) {
        width: 160px;
    }
`;

const SocialContainer = styled.div`
    display: flex;
    align-items: center;

    ul {
        display: flex;
        align-items: center;
        padding-left: 0px;
    }

    & li + li {
        margin-left: 16px;
    }
`;

const FooterLinkContainer = styled.ul`
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-left: 16px;
    margin-top: 0;
    margin-bottom: 10px;

    @media (min-width: 0) and (max-width: 640px) {
        margin-left: 0px;
    }
`;

const StyledLink = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
`;

const Text = styled.p`
    font-family: SpaceMono;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 10px;

    @media (min-width: 0) and (max-width: 640px) {
        display: none;
    }
`;

const Anchor = styled.a`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FooterLink = ({ url, img, alt }) => (
    <StyledLink>
        <Anchor rel="noreferrer" target="_blank" href={url}>
            <img src={img} alt={alt} />
        </Anchor>
    </StyledLink>
);

const Footer = () => (
    <Section>
        <Container>
            <AcLogo src={acLogo} alt="Academia de Código" />
            <SocialContainer>
                <Text>
                    Stalk us on <span>-</span>
                </Text>
                <FooterLinkContainer>
                    <FooterLink
                        url="https://www.facebook.com/academiadecodigo"
                        alt="Facebook"
                        img={fbLogo}
                    />
                    <FooterLink
                        url="https://www.instagram.com/academiadecodigo"
                        alt="Instagram"
                        img={igLogo}
                    />
                    <FooterLink
                        url="https://www.twitter.com/academia_codigo"
                        alt="Twitter"
                        img={twLogo}
                    />
                    <FooterLink
                        url="https://www.youtube.com/channel/UCFefYbYuXZNcfSsY2NbI_YA"
                        alt="YouTube"
                        img={ytLogo}
                    />
                    <FooterLink
                        url="https://www.linkedin.com/school/9363606"
                        alt="LinkedIn"
                        img={liLogo}
                    />
                </FooterLinkContainer>
            </SocialContainer>
        </Container>
    </Section>
);

export default Footer;
