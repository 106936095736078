import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CampusInfoContainer = styled.div`
    margin-bottom: 40px;
    padding-left: 0px;
    color: #ffffff;
    font-size: 16px;
    line-height: 1.8em;
`;

const Mark = styled.mark`
    color: #e02526;
    background-color: transparent;
`;

const InfoSpan = styled.span`
    color: #ffffff;
`;

const InfoValue = styled(InfoSpan)`
    ::before {
        color: #e02526;
        content: "'";
    }

    ::after {
        color: #e02526;
        content: "'";
    }
`;

const InfoValueDisable = styled(InfoSpan)`
    text-decoration: line-through;
    color: white;
    ::before {
        color: #e02526;
        content: "'";
    }

    ::after {
        color: #e02526;
        content: "'";
    }
`;


const InfoLabel = styled.span`
    opacity: 0.7;

    ::after {
        color: #e02526;
        content: ': ';
    }
`;

const InfoP = styled.div`
    padding-left: 40px;
`;

const CampusInfo = ({ campus }) => (
    <CampusInfoContainer>
        <Mark>const</Mark>
        {' startLearning_JS_OS '}
        <Mark>{' = {'}</Mark>

        <InfoP>
            <InfoLabel>availableSeats</InfoLabel>
            <InfoValue>{campus.info.seats}</InfoValue>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>startDate</InfoLabel>
            <InfoValueDisable>{campus.info.startDate}</InfoValueDisable>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>endDate</InfoLabel>
            <InfoValueDisable>{campus.info.endDate}</InfoValueDisable>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>duration</InfoLabel>
            <InfoValue>{campus.info.duration}</InfoValue>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>location</InfoLabel>
            <InfoValue>{campus.info.location}</InfoValue>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>schedule</InfoLabel>
            <InfoValue>{campus.info.schedule }</InfoValue>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>requirements</InfoLabel>
            <InfoValue>{campus.info.requirements}</InfoValue>
            <Mark>,</Mark>
        </InfoP>

        <InfoP>
            <InfoLabel>1stEditionPrice</InfoLabel>
            <InfoValue>{campus.info.price}&euro;</InfoValue>
        </InfoP>

        <Mark>{'}'}</Mark>
    </CampusInfoContainer>
);

CampusInfo.propTypes = {
    campus: PropTypes.shape().isRequired,
    bgImage: PropTypes.node.isRequired,
    bgPos: PropTypes.string.isRequired
};

export default CampusInfo;
