import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { init as googleInit } from '../analytics/google';
import { init as initPixel } from '../analytics/facebook';

import Layout from '../layout';
import Hero from '../sections/hero';
import Campi from '../sections/campi';
import About from '../sections/about';
import Faq from '../sections/faq';
import Wtf from '../sections/wtf';
import Footer from '../sections/footer';

const IndexPage = ({ location }) => {
    const [campus, setCampus] = useState();
    useAnalytics();

    return (
        <Layout title="NEW COURSES" url={location.origin}>
            <Hero />
            <Campi onFaq={setCampus} />
            <About />
            <Faq campusId={campus} />
            <Wtf />
            <Footer />
        </Layout>
    );
};

export default IndexPage;

IndexPage.propTypes = {
    location: PropTypes.shape({
        href: PropTypes.string.isRequired
    }).isRequired
};

function useAnalytics() {
    useEffect(() => {
        // inits google tag analytics
        googleInit();
        // inits facebook analytics
        initPixel();
    }, []);
}
