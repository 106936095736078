import styled from 'styled-components';

const Tab = styled.h3`
    opacity: ${({ active }) => (active ? 1 : 0.2)};
    padding-bottom: 20px;
    margin-right: 40px;
    color: #ffffff;
    border-bottom: ${({ active }) => (active ? "0px solid #e31d1a" : "0px solid transparent")};

    @media (min-width: 0) and (max-width: 1024px) {
        margin-right: 20px;
    }

    @media (min-width: 0) and (max-width: 640px) {
        word-wrap: break-word;
    }
`;

export default Tab;
