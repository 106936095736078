import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.video`
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;

    ::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }
`;

const HeroVideo = ({ src, poster }) => (
    <Video autoPlay muted loop poster={poster}>
        {src.map((videoSrc, index) => (
            <source key={index} src={videoSrc} />
        ))}
    </Video>
);

HeroVideo.propTypes = {
    src: PropTypes.arrayOf(PropTypes.node).isRequired,
    poster: PropTypes.node.isRequired
};

export default HeroVideo;
