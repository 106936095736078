import React from 'react';
import styled from 'styled-components';

/* assets */
import Outsystems from '../../assets/images/OutSystems-logo-digital-2018-white.svg';

const Background = styled.div`
    padding: 20px 40px 40px 40px;
    background-color: #e31d1a;
    background-image: 
        radial-gradient(
            circle at center right, 
            rgba(0,0,0,0) 35%, 
            rgba(0,0,0,0.2) 35%, 
            rgba(0,0,0,0.2) 65%, 
            rgba(0,0,0,0) 65%
    );
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center right;
    margin-top: 140px;
    border-radius: 20px;

    @media (min-width: 0) and (max-width: 1024px) {
        margin-top: 20px;
        border-radius: 10px;
    }

    @media (min-width: 0) and (max-width: 768px) {
        padding: 20px 20px 30px 20px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const OSLogo = styled.div`
    width: 140px;
    height: 80px;
    background-image: url(${Outsystems});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    margin-right: 30px;
`;

const Title = styled.h4`
    color: white;
    margin-top: 5px;
    font-family: "Open Sans";
    font-size: 24px;
`;

const Description = styled.p`
    max-width: 700px;
    color: white;
`;

const Section = () => (
    <Background>
        <LogoContainer>
            <OSLogo />
            <Title>Certified Training Partner</Title>
        </LogoContainer>
        <Description>&lt;Code For All_&gt; (aka &#60;Academia de Código_&#62;) is one of the few OutSystems Certified Training Partners. Known for turning newbies into low-code experts with more than 240 students trained so far and average score exams of 83%.</Description>
    </Background>
);

export default Section;
