import styled from 'styled-components';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
        margin-right: 20px;
    }
`;

export default ButtonContainer;
