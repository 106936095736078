import styled from 'styled-components';

const Wrapper = styled.div`
    width: calc(100% - 80px);
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 0) and (max-width: 768px) {
        width: calc(100% - 40px);
    }
`;

export default Wrapper;
