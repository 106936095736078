import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Button } from '../../components/buttons';

const Description = styled.div`
    line-height: 1.8em;
    font-size: 16px;
    color: white;
    max-width: 700px;
    margin-top: 20px;

    p {
        font-family: OpenSans;
        margin-bottom: 2em;
    }
`;

const Title = styled.h4`
    color: #ffffff;
    margin-bottom: 20px;
`;

const Module = styled.h5`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px 30px 0px;
    border-bottom: 1px solid #545454;
    color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const Bootcamp = ({ bootcamp }) => (
    <>
        <Description>
            {bootcamp.description.map((line, index) => (
                <p key={index}>{line}</p>
            ))}
            <Title>Syllabus</Title>
        </Description>
        {bootcamp.syllabus.map((item, index) => (
            <Module key={index}>
                {item.module}
                <span>{item.duration} hours</span>
            </Module>
        ))}
        {/*<ButtonContainer>
            <Button to="/#campi" as={Link}>
                &lt;Apply A$AP&gt;
            </Button>
        </ButtonContainer>*/}
    </>
);

export default Bootcamp;
